import {Decimal} from 'decimal.js';

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const utcDateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  timeZone: 'UTC',
});

export const toNum = (num: number | string | Decimal): number => {
  if (typeof num === 'number') {
    return num;
  } else if (typeof num === 'string') {
    return parseFloat(num);
  } else if (Decimal.isDecimal(num)) {
    return num.toNumber();
  } else {
    return NaN;
  }
}

export const formatMoney = (num: number | string | Decimal) => {
  return moneyFormatter.format(toNum(num));
}

export const formatDate = (date: Date): string => {
  return dateFormatter.format(date);
}

export const formatUtcDate = (date: Date): string => {
  return utcDateFormatter.format(date);
}
